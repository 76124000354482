@import '~@/style/vars.less';

.@{layout} {
  height: 100vh;
  width: 100%;
  

  .@{layout}-sider {
    margin-top: @margin-1;
    box-shadow: 1px 1px 5px #d9d9d9;
  }
  .vsf-layout-header2 {
    width: 100%;
    height: 100px;
  }
  .@{layout}-header {
    width: 100%;
    height: 100px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    .vsf-layout-header_content {
      width: 1100px;
      height: 100%;
      background: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .vsf-layout-header_content_name {
        width: 170px;
        // height: 100px;
        font-size: 36px;
        font-weight: bold;
        color: #fff;
      }
      .vsf-layout-header_content_title {
        width: 520px;
        height: 100%;
        font-size: 14px;
        color: #ffffff80;
        display: flex;
        justify-content: center;
        align-items: center;

        .vsf-layout-header_content_title_a {
          width: 86px;
          text-align: center;
          color: #ffffff80;

          &:hover {
            color: #fff;
          }
          &:active {
            color: #fff;
          }
        }
      }
    }
  }

  .@{layout}-content {
    width: 100%;
    // background: #f1913a;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    .vsf-layout-content_img {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 30px 50px;
      .vsf-layout-content_img_img1 {
        width: 300px;
      }
      .vsf-layout-content_img_logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .vsf-layout-content_img_logo_img {
          width: 100px;
          height: 100px;
          border-radius: 20px;
        }
        .vsf-layout-content_img_logo_p {
          font-size: 30px;
          font-weight: 300;
          color: #000;
        }
        .vsf-layout-content_img_logo_p1 {
          font-size: 38px;
          font-weight: bold;
          color: #000;
        }
        .vsf-layout-content_img_logo_a {
          width: 204px;
          height: 54px;
          background: #f1913a;
          color: #fff;
          font-size: 28px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
        }
        .vsf-layout-content_img_logo_btn {
          flex: 1;
          display: flex;
          justify-content: center;
          align-content: center;
          .vsf-layout-content_img_logo_btn_item {
            width: 175px;
            height: 60px;
            border: 0;
            border-radius: 20px;
            background: #f1913a;
            margin-left: 10px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            // margin: 0 auto;
          }
        }
      }
    }
    .vsf-layout-content_img1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 30px 50px;
      .vsf-layout-content_img_img1 {
        width: 300px;
      }
      .vsf-layout-content_img_logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .vsf-layout-content_img_logo_img {
          width: 100px;
          height: 100px;
          border-radius: 20px;
        }
        .vsf-layout-content_img_logo_p {
          font-size: 30px;
          font-weight: 300;
          color: #000;
        }
        .vsf-layout-content_img_logo_p1 {
          font-size: 38px;
          font-weight: bold;
          color: #000;
        }
        .vsf-layout-content_img_logo_a {
          width: 204px;
          height: 54px;
          background: #f1913a;
          color: #fff;
          font-size: 28px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
        }
        .vsf-layout-content_img_logo_btn {
          flex: 1;
          display: flex;
          justify-content: center;
          align-content: center;
          .vsf-layout-content_img_logo_btn_item {
            width: 175px;
            height: 60px;
            border: 0;
            border-radius: 20px;
            background: #f1913a;
            margin-left: 10px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            // margin: 0 auto;
          }
        }
      }
    }
  }
  .vsf-layout-foot {
    height: 200px;
    width: 100%;
    background: #000;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 90px 0;
    .vsf-layout-foot_p {
      color: #fff;
    }
  }

  .@{layout}-main {
    flex: 1;
    padding: @padding;
    margin-top: @margin-1;
    overflow-y: auto;
  }
}

@root-entry-name: default;