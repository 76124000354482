.index_content {
  .index_content_img_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .index_content_img {
    width: 1024px;
    height: 600px;
    // background: red;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .index_content_img_item {
    //  width: 400px; 
    }
  }
  .index_content_foot {
    // border: 1px solid red;
    margin-top: 280px;
    // padding-bottom: 30px;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #cac8c8;
      div {
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .index_content_foot_a {
    padding: 0 5px;
    border-right: 1px #fff solid;
    color: #cac8c8;
    &:hover {
      color: #fff;
    }
  }
}

@root-entry-name: default;