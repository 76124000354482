.recharge {
    height: 100vh;
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #FAFAFA;
    padding: 20px;
    &__header {
        height: 100px;
    }
    &__content {
        width: 50%;
        min-width: 300px;
        // height: 200px;
        background: #fff;
        border-radius: 20px;
        padding: 20px;
        &__header {
            display: flex;
            margin-bottom: 10px;
            &__name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                // align-items: center;
            }
            &__img {
                width: 50px;
                height: 50px;
                // background: red;
                border-radius: 50%;
            }
        }
        &__money {
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: 100px;
                height: 50px;
                // background: red;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                border-radius: 10px;
                margin-left: 10px;
                border: 1px solid rgba(0,0,0,.1);
                margin-bottom: 30px;
            }
        }
    }
}
@root-entry-name: default;