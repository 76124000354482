// 滚动条整体宽度
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}


// 滚动条滑槽样式
::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

// 滚动条样式
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #ddd;
    // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}


::-webkit-scrollbar-corner {
    background-color: transparent;
}

ul,
ol,
dl {
    list-style: none;
}
@root-entry-name: default;